<template>
  <div id="mapContainer" v-loading="loading" element-loading-text="LOADING...">
    <el-button v-if="this.selectedProject._id === '633d2757a4fbd61d20ee34ae1'" ref="toggleButton"
      class="custom-button-size" icon="el-icon-info" @click="toggleInstrumentCounts"></el-button>

    <el-popover v-model="showPopover" ref="popover" placement="bottom-end" width="455" height="580" trigger="manual">
      <img :src="shakemapImageURI" style="width:100%;" alt="image" />
    </el-popover>

    <div v-popover:popover class="mapView" ref="mapDiv"></div>
    <div class="navigation" @click="toggleShowSettings">
      <font-awesome-layers class="fa-lg icon-spin">
        <font-awesome-icon icon="cog"></font-awesome-icon>
      </font-awesome-layers>
    </div>
    <div class="settings-pane" v-show="showSettings">
      <div class="settings-content">
        <!-- Add your settings content here -->
        <div class="widget-settings-header">
          <div class="flex-row justify-between align-center">
            <h4 class="title is-4 is-marginless">{{ $t('map.settings') }}</h4>
            <font-awesome-layers class="has-cursor-pointer has-text-grey fa-lg" @click="toggleShowSettings">
              <font-awesome-icon icon="angle-double-left"></font-awesome-icon>
            </font-awesome-layers>
          </div>
        </div>

        <div class="widget-settings-layer">
          <p class="menu-label">Basemap</p>
          <el-select v-model="selectedLayer" placeholder="選擇圖層" @change="changeLayer" style="width:180px;">
            <el-option v-for="(layer, index) in wmtsLayers" :key="index" :label="reloadSetting(layer.activeLayer.id)"
              :value="index"></el-option>
          </el-select>
        </div>
        <div class="widget-settings-layer">
          <p class="menu-label">Select Layers</p>
          <el-checkbox v-model="showTextGraphic" @change="toggleTextGraphic" v-if="textShow"
            style="margin-right:10px; padding-right: 20px;">
            {{ $t('map.instrument_id') }}
          </el-checkbox>
          <el-checkbox v-model="layerVisible.moi.visible" @change="selectLayers('等高線', layerVisible.moi.visible)"
            v-if="shouldShow_Layers.contour" style="margin-right:10px; padding-right: 20px;">
            {{ $t('map.contour') }}
          </el-checkbox>
          <el-checkbox v-model="layerVisible.typhon.visible" @change="selectLayers('颱風', layerVisible.typhon.visible)"
            v-if="shouldShow_Layers.typhon" style="margin-right:10px; padding-right: 20px;">
            {{ $t('map.typhon') }}
          </el-checkbox>
          <el-checkbox v-model="layerVisible.echo.visible" @change="selectLayers('雷達', layerVisible.echo.visible)"
            v-if="shouldShow_Layers.echo" style="margin-right:10px; padding-right: 20px;">
            {{ $t('map.echo') }}
          </el-checkbox>
          <el-checkbox v-model="layerVisible.rain.visible" @change="selectLayers('累積雨量', layerVisible.rain.visible)"
            v-if="shouldShow_Layers.rainfall" style="margin-right:10px; padding-right: 20px;">
            {{ $t('map.rainfall') }}
          </el-checkbox>
          <el-radio v-model="selectedOption" label="3" @change="updateCustomLayerUrl()"
            v-show="layerVisible.rain.visible">
            {{ $t('map.rainfall') }} (3H)
          </el-radio>
          <el-radio v-model="selectedOption" label="6" @change="updateCustomLayerUrl()"
            v-show="layerVisible.rain.visible">
            {{ $t('map.rainfall') }} (6H)
          </el-radio>
          <el-radio v-model="selectedOption" label="12" @change="updateCustomLayerUrl()"
            v-show="layerVisible.rain.visible">
            {{ $t('map.rainfall') }} (12H)
          </el-radio>
          <el-radio v-model="selectedOption" label="24" @change="updateCustomLayerUrl()"
            v-show="layerVisible.rain.visible">
            {{ $t('map.rainfall') }} (24H)
          </el-radio>

          <el-checkbox v-model="layerVisible.shp.visible" @change="selectLayers('地質敏感', layerVisible.shp.visible)"
            v-if="shouldShow_Layers.shp" style="margin-right:10px; padding-right: 20px;">
            {{ $t('map.shp') }}
          </el-checkbox>
          <el-radio v-model="selectedOptionSHP" label="11" @change="updateCustomLayerUrl_SHP()"
            v-show="layerVisible.shp.visible">
            桃園市
          </el-radio>
          <el-radio v-model="selectedOptionSHP" label="12" @change="updateCustomLayerUrl_SHP()"
            v-show="layerVisible.shp.visible">
            新竹縣市
          </el-radio>
          <el-radio v-model="selectedOptionSHP" label="13" @change="updateCustomLayerUrl_SHP()"
            v-show="layerVisible.shp.visible">
            苗栗縣
          </el-radio><br>
          <el-radio v-model="selectedOptionSHP" label="14" @change="updateCustomLayerUrl_SHP()"
            v-show="layerVisible.shp.visible">
            彰化縣
          </el-radio>
        </div>

        <div class="widget-settings-layer" v-if="shouldShow_Layers.earthquake">
          <p class="menu-label">External Resources</p>
          <el-checkbox @change="showPopover = !showPopover" style="margin-right:10px; padding-right: 20px;">
            {{ $t('map.earthquake') }}
          </el-checkbox>
        </div>

        <div class="widget-settings-filter" v-if="filterShow">
          <p class="menu-label">Select Instruments</p>
          <el-checkbox-group class="flex-column" v-model="selectedInstruments" size="medium">
            <el-checkbox v-for="typeName in instrumentType" :key="typeName" :label="typeName" :checked="true"
              @change="(checked) => handleCheckboxChange(checked, typeName)">
              {{ typeName }}
            </el-checkbox>
          </el-checkbox-group>
        </div>

        <div class="widget-settings-err">
          <p class="menu-label" @click="toggleInstrumentsStatus">Instruments Status ⮟</p>
          <ul v-show="instrumentsStatusVisible">
            <li v-for="instrument in badInstrumentStatusList" :key="instrument.index"
              style="list-style-type: none; font-size: 14px;" @click="showMarker(instrument)" class="instrument-item">
              <span class="dot" :style="{ 'background-color': getStatusColor(instrument.status) }"></span>
              <span>{{ instrument.name }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
#mapContainer {
  position relative
  top 0
  left 0
  width 100%
  height 100vh
  max-height: 850px;
}

.mapView {
  width: 100%;
  height: 100%;
}

.flex-column {
  display: grid;
  grid-template-columns: repeat(3, 4.5rem);
  gap: 0px;
  padding: 0;
}
.instrument-item {
  display: flex;
  align-items: center;
}
::-webkit-scrollbar {
  display: none;
}
.navigation {
  position absolute
  cursor pointer
  display flex
  justify-content center
  align-items center
  z-index 6
  top 0
  left 0
  margin 30px
  width 4rem
  height 4rem
  background-color white
  border-radius 50%
  box-shadow 0 2px 12px 0 rgba(0, 0, 0, 0.1)
}
.dot
  height: 10px;
  width: 10px;
  margin-right: 10px
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
.settings-pane
  overflow-y auto
  position absolute
  z-index 7
  top 0
  height 98.3%
  width 260px
  background-color white
  transition-property transform
  transform translateX(0)
  box-shadow 0 -1px 24px rgba(0, 0, 0, 0.4)
  transition-duration 0.3s
  .settings-content
    overflow-y auto
  .widget-settings-header
    padding 1rem 1.5rem
  .widget-settings-layer, .widget-settings-filter, .widget-settings-err
    padding 1rem 1.5rem
    border-top 1px solid #d9d9d9
    color #777
</style>

<style scoped>
.custom-button-size {
  padding: 0px !important;
  height: 32px;
  width: 32px;
  line-height: 32px;
}

.custom-button-size .el-icon-info {
  line-height: 32px;
}

.instrument-counts {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

::v-deep .calcite .esriPopup .actionsPane .action:first-child {
  display: none;
}

::v-deep [class*="esri-popup--is-docked-top-"] .esri-popup__footer,
[class*="esri-popup--aligned-bottom-"] .esri-popup__footer {
  border-bottom: solid 1px #6e6e6e4d;
}

::v-deep .esri-popup__header {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px;
  background-color: #fff;
  color: #fff;
}

::v-deep .esri-popup--shadow {
  box-shadow: 0 1px 4px rgb(155, 155, 155) !important;
}

::v-deep .esri-popup__header-title {
  background-color: #409EFF !important;
}

::v-deep .esri-popup__header-container,
.esri-popup__header-container--button {
  outline: none !important;
}

::v-deep .esri-popup__icon,
.esri-icon-close {
  color: #000000 !important;
}

::v-deep .esri-ui .esri-popup {
  border-radius: 5px !important;
}

::v-deep .esri-popup__button {
  background-color: transparent !important;
  outline: none;
}

::v-deep .esri-view .esri-view-surface--inset-outline:focus::after {
  outline: auto 0px Highlight !important;
  outline: auto 0px -webkit-focus-ring-color !important;
}

.esri-view .esri-view-surface--touch-none:focus::after {
  outline: none !important;
}

::v-deep .esri-view-width-xlarge .esri-popup__main-container {
  width: 330px !important;
  border-radius: 5px !important;
}

::v-deep .esri-popup__header-title {
  font-weight: 600 !important;
}
</style>

<script>
import { loadModules } from 'esri-loader';
import moment from 'moment';
import axios from 'axios';
import i18n from '../../i18n/index.js';
import { geojsonToArcGIS } from '@esri/arcgis-to-geojson-utils';

// 'GREEN': require('@/assets/imgur/thsr.png'),
export default {
  data() {
    return {
      instrumentsStatusVisible: false,
      option: {
        url: 'https://js.arcgis.com/4.18/',
        css: 'https://js.arcgis.com/4.18/esri/themes/light/main.css',
      },
      markerPics: {
        'GREEN': require('@/assets/imgur/MHUKBDR.png'),
        'AL': require('@/assets/imgur/hs3jCEL.png'),
        'WSL': require('@/assets/imgur/J3geGVe.png'),
        'down': require('@/assets/imgur/ZeDDvon.png'),
        'WL': require('@/assets/imgur/s5Jqg7J.png'),
        'default': require('@/assets/imgur/MHUKBDR.png'),
      },
      mapSetting: {
        basemap: 'topo-vector',
        zoom: 8,
        center: [120.606220, 24.05705],
        constraints: {
          maxZoom: 19,
          rotationEnabled: false,
        },
      },
      kmlLayerConfigs: [
        {
          id: 'typhon',
          name: i18n.t('map.typhon'),
          url: 'https://alerts.ncdr.nat.gov.tw/DownLoadNewAssistData.ashx/1',
        },
      ],
      wmtsLayerConfigs: [
        {
          id: 'None',
          name: 'Default',
          url: '',
        },
        {
          id: 'PHOTO_MIX',
          name: i18n.t('map.orthoimagery_mix'),
          url: 'https://wmts.nlsc.gov.tw/wmts/PHOTO_MIX/{Style}/{TileMatrixSet}/{TileMatrix}/{TileRow}/{TileCol}',
        },
        {
          id: 'EMAP',
          name: i18n.t('map.general_electronic_map'),
          url: 'https://wmts.nlsc.gov.tw/wmts/EMAP/{Style}/{TileMatrixSet}/{TileMatrix}/{TileRow}/{TileCol}',
        },
        {
          id: 'PHOTO2',
          name: i18n.t('map.orthoimagery_general'),
          url: 'https://wmts.nlsc.gov.tw/wmts/PHOTO2/{Style}/{TileMatrixSet}/{TileMatrix}/{TileRow}/{TileCol}',
        },
        {
          id: 'MOI_CONTOUR_2',
          name: i18n.t('map.contour'),
          url: 'https://wmts.nlsc.gov.tw/wmts/MOI_CONTOUR_2/{Style}/{TileMatrixSet}/{TileMatrix}/{TileRow}/{TileCol}',
        },
        // You can easily add more layers here
      ],
      customLayerConfigs: [
        {
          id: 'echo',
          name: '雷達回波圖',
          url: 'https://cwaopendata.s3.ap-northeast-1.amazonaws.com/Observation/O-A0058-005.png',
          extent: {
            xmin: 115.00, // lon1
            ymin: 17.75, // lat1
            xmax: 126.5, // lon2
            ymax: 29.25, // lat2
          },
          canvasSize: {
            width: 3600,
            height: 3600,
          },
        },
        {
          id: 'rain',
          name: '累積雨量圖',
          url: 'https://api-geoserver.geosupply.com.tw/rain/高鐵邊坡監測/HOUR_24.png',
          extent: {
            xmin: 119.77, // lon1
            ymin: 21.746776427875963, // lat1
            xmax: 122.67, // lon2
            ymax: 25.449655229327627, // lat2
          },
          canvasSize: {
            width: 1347,
            height: 1888,
          },
        },
        // You can easily add more layers here
      ],
      GraphicsLayerConfigs: [
        {
          id: 'L0011',
          name: '桃園市',
          url: 'http://127.0.0.1:8080/shp/L0011.zip',
        },
        {
          id: 'L0012',
          name: '新竹縣市',
          url: 'http://127.0.0.1:8080/shp/L0012.zip',
        },
        {
          id: 'L0013',
          name: '苗栗縣',
          url: 'http://127.0.0.1:8080/shp/L0013.zip',
        },
        {
          id: 'L0014',
          name: '彰化縣',
          url: 'http://127.0.0.1:8080/shp/L0014.zip',
        },
      ],
      esriModules: {},
      activeGraphics: {},
      shouldShow_Layers: {
        contour: false,
        typhon: false,
        instrument_name: false,
        echo: false,
        rainfall: false,
        earthquake: false,
        shp: false,
      },
      showTextGraphic: true,
      textShow: false,
      filterShow: false,
      instrumentList: [],
      wmtsLayers: [],
      selectedInstruments: [],
      map: null,
      loading: true,
      showSettings: true,
      showPopover: false,
      now_zoom: 0,
      selectedOption: '24',
      selectedOptionSHP: null,
      shakemapImageURI: '',
      instrumentType: ['STI', 'MC', 'DS', 'SAA', 'TM', 'LC', 'RAIN', 'ELP', 'ETI'],
      layerVisible: {
        moi: { name: '等高線', visible: false },
        echo: { name: '雷達回波', visible: false },
        rain: { name: '累積雨量', visible: false },
        typhon: { name: '颱風', visible: false },
        shp: { name: '地質敏感', visible: false },
      },
      CustomImageOverlayLayer: null,
      selectedLayer: 0,
      showInstrumentCounts: false,
      counts: {
        STI: { num: 0, status: 'GREEN' },
        MC: { num: 0, status: 'GREEN' },
        DS: { num: 0, status: 'GREEN' },
        SAA: { num: 0, status: 'GREEN' },
        TM: { num: 0, status: 'GREEN' },
        LC: { num: 0, status: 'GREEN' },
        RAIN: { num: 0, status: 'GREEN' },
        ELP: { num: 0, status: 'GREEN' },
        ETI: { num: 0, status: 'GREEN' },
      },
    };
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    userRole() {
      return this.$store.getters['user/details'].roles;
    },
    badInstrumentStatusList() {
      // v-show="instrument.coordinates && instrument.coordinates[0] > 100 && instrument.status !== 'GREEN'"
      let instrumentList = this.instrumentList.filter(instrument => instrument.coordinates && instrument.coordinates[0] > 100 && instrument.status !== 'GREEN');
      const keys = {};
      instrumentList = instrumentList.filter(instrument => {
        if (instrument.name.includes('TK66')) return false;
        const key = `${instrument.coordinates[0]},${instrument.coordinates[1]}`;
        if (keys[key]) return false;
        keys[key] = true;
        return true;
      });
      return instrumentList;
    },
  },
  async mounted() {
    // ESRI 模組名稱
    const moduleNames = ['Map', 'MapView', 'ScaleBar', 'Graphic', 'BaseDynamicLayer', 'WMTSLayer', 'KMLLayer', 'SpatialReference', 'GraphicsLayer', 'Locate'];

    // 用於存儲 ESRI 模組的路徑
    const modulePaths = [
      'esri/Map',
      'esri/views/MapView',
      'esri/widgets/ScaleBar',
      'esri/Graphic',
      'esri/layers/BaseDynamicLayer',
      'esri/layers/WMTSLayer',
      'esri/layers/KMLLayer',
      'esri/geometry/SpatialReference',
      'esri/layers/GraphicsLayer',
      'esri/widgets/Locate',
    ];

    // 透過 loadModules 加載模組
    const modules = await loadModules(modulePaths, this.option);

    let project_lon = parseFloat(this.$store.getters['project/selectedProject'].lon);
    let project_lat = parseFloat(this.$store.getters['project/selectedProject'].lat);
    let project_zoom = parseInt(this.$store.getters['project/selectedProject'].zoom, 10);

    if (!isNaN(project_lon) && !isNaN(project_lat) && !isNaN(project_zoom)) {
      this.mapSetting.center = [project_lon, project_lat];
      this.mapSetting.zoom = project_zoom;
      console.log(this.mapSetting.center);
      console.log(this.mapSetting.zoom);
    }

    // 以模組名稱作為 key，將模組值設為模組本身
    moduleNames.forEach((moduleName, index) => {
      this.esriModules[moduleName] = modules[index];
    });

    // 創建地圖
    this.map = new this.esriModules.Map({
      basemap: this.mapSetting.basemap,
      layer: [],
    });

    // 創建 WMTS 圖層
    this.wmtsLayerConfigs.forEach((config) => {
      const wmtsLayer = new this.esriModules.WMTSLayer({
        url: config.url,
        serviceMode: 'KVP',
        activeLayer: {
          id: config.id,
          name: config.name,
        },
        visible: false,
      });
      if (wmtsLayer.activeLayer.id !== 'MOI_CONTOUR_2')
        this.wmtsLayers.push(wmtsLayer);
      if (wmtsLayer.url !== '')
        this.map.add(wmtsLayer);
    });

    // 創建 KML 圖層
    this.kmlLayerConfigs.forEach((config) => {
      const kmlLayer = new this.esriModules.KMLLayer({
        url: config.url,
        visible: false,
        title: '颱風路徑圖',
      });
      if (kmlLayer.url !== '')
        this.map.add(kmlLayer);
    });

    if (typeof (this.$route.params.lon) !== 'undefined') {
      this.mapSetting.center = [this.$route.params.lon, this.$route.params.lat];
      this.mapSetting.zoom = 18;
    }

    // 創建地圖視圖
    this.view = new this.esriModules.MapView({
      container: this.$refs.mapDiv,
      map: this.map,
      zoom: this.mapSetting.zoom,
      center: this.mapSetting.center,
      constraints: this.mapSetting.constraints,
    });

    // 等待地图视图初始化完成
    this.view.when(() => {
      this.view.ui.remove('zoom');
      this.view.ui.add(
        new this.esriModules.ScaleBar({
          view: this.view,
          style: 'ruler',
          unit: 'metric',
        }), {
          position: 'bottom-right',
        });
      const locateBtn = new this.esriModules.Locate({
        view: this.view,
        popupEnabled: false,
      });
      this.view.ui.add(locateBtn, {
        position: 'top-right',
      });
      this.view.popup = {
        collapseEnabled: false,
        dockOptions: {
          buttonEnabled: false,
        },
        actions: [],
      };

      if (this.selectedProject._id === '633d2757a4fbd61d20ee34ae1') {
        this.$nextTick(() => {
          const toggleButton = this.$refs.toggleButton.$el;
          this.view.ui.add(toggleButton, 'top-right');

          const instrumentCounts = document.createElement('div');
          instrumentCounts.className = 'instrument-counts';
          instrumentCounts.style.display = 'none';

          this.updateInstrumentCountsContent(instrumentCounts);
          this.view.ui.add(instrumentCounts, 'top-right');
        });
      }

      // this.view.popup.featureNavigation = false;
      this.overrideBaseDynamicLayer();
      //console.log(this.view);
      this.customLayerConfigs.forEach((config) => {
        const customLayer = new this.CustomImageOverlayLayer({
          title: config.name,
          picUrl: config.url,
          extent: config.extent,
          view: this.view,
          cSize: config.canvasSize,
          visible: false,
        });
        this.map.add(customLayer);
      });

      this.view.popup.on('trigger-action', function (event) {
        if (event.action.id === 'show-instrument-id') {
          let instrumentId = this.view.popup.selectedFeature.attributes.instrument_id;
          window.open(`/chart?instrument=${instrumentId}`, '_blank');
        }
      });

      this.getShakemapImageURI();
      this.getProject();
      //console.log(this.map);
      //console.log(this.view);
    });
  },
  methods: {
    toggleInstrumentsStatus() {
      this.instrumentsStatusVisible = !this.instrumentsStatusVisible;
    },
    updateInstrumentCountsContent(element) {
      if (this.selectedProject._id === '633d2757a4fbd61d20ee34ae') {
        let content = '';
        for (const [key, value] of Object.entries(this.counts)) {
          if (value.num > 0) {
            let color = '';
            switch (value.status) {
              case 'GREEN':
                color = '#4eff56';
                break;
              case 'AL':
                color = '#ffd800';
                break;
              case 'WSL':
                color = '#ff0000';
                break;
              default:
                color = '#4eff56';
            }
            content += `<div><span class="status-dot" style="background-color: ${color}; width: 10px; height: 10px; border-radius: 50%; display: inline-block; border: 1px solid black;"></span> ${key}: ${value.num}</div>`;
          }
        }
        element.innerHTML = content;
      }
    },
    async loadZipFromUrl(url) {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const fileBlob = await response.blob();
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(fileBlob);
        fileReader.onload = () => {
          const shapefile = require('shpjs/dist/shp');
          shapefile.parseZip(fileReader.result).then(geojson => {
            //console.log('zip load', geojson);
            let result = this.addGeoJsonToMap(geojson, '地質敏感');
            return result;
          });
        };
      } catch (error) {
        console.error('loading .zip file error:', error);
        alert('loading .zip file error:');
        return 'err';
      }
    },
    addGeoJsonToMap(geoJson, name) {
      let graphics = [];
      let layerStyleType;
      let symbol;

      // 根据GeoJSON的类型设置符号样式
      switch (geoJson.features?.at(0).geometry.type ?? 'Polygon') {
        case 'Point':
          symbol = {
            type: 'simple-marker',
            color: 'rgba(226, 119, 40)',
            outline: {
              color: 'rgba(255, 255, 255)',
              width: 2,
            },
          },
          layerStyleType = 'point';
          break;
        case 'LineString':
          symbol = {
            type: 'simple-line',
            color: 'rgba(227, 139, 79, 0.8)',
            width: 3,
            cap: 'round',
            join: 'round',
          },
          layerStyleType = 'polyline';
          break;
        case 'Polygon':
        case 'MultiPolygon':
          symbol = {
            type: 'simple-fill',
            color: 'rgba(227, 139, 79, 0.8)',
            outline: {
              color: 'rgba(255, 255, 255)',
              width: 1,
            },
          },
          layerStyleType = 'polygon';
          break;
        default:
          console.log('default');

      }

      if (!layerStyleType) return;

      geoJson.features.forEach(value => {
        let graphic = geojsonToArcGIS(value);
        graphic.geometry.spatialReference = new this.esriModules.SpatialReference({ wkid: 4326 });
        graphic.geometry.type = layerStyleType;
        graphic.symbol = symbol;
        graphics.push(graphic);
      });

      let layer = new this.esriModules.GraphicsLayer({
        title: name,
        graphics,
        symbol,
        layerStyleType,
        spatialReference: new this.esriModules.SpatialReference({ wkid: 4326 }),
        // attributes: ... // 如果需要添加属性
      });

      this.map.add(layer);
      console.log('add');
      return 'add';
    },
    getProject() {
      this.$axios({
        category: 'project',
        method: 'get',
        apiName: `${this.selectedProject._id}/station-instrument`,
      })
        .then(res => {
          try {
            this.$store.getters['project/selectedProject'].allowed_layer.forEach(lyr => {
              this.shouldShow_Layers[lyr] = true;
            });
          } catch (error) {
            //pass
          }
          if (this.selectedProject._id === '633d2757a4fbd61d20ee34ae' || this.userRole === 'thsr' || this.userRole === 'admin') {
            this.shouldShow_Layers.moi = true;
            this.shouldShow_Layers.typhon = true;
            this.shouldShow_Layers.echo = true;
            this.shouldShow_Layers.rainfall = true;
            this.shouldShow_Layers.earthquake = true;
            this.shouldShow_Layers.shp = true;
          }
          res.data.map(station => {
            station.instrument.map(item => {
              this.instrumentList.push(item);
              this.addMarker(item.coordinates, item.name, item.reading, item.TIMESTAMP, item.status, item._id);
            });
          });
          this.initializeZoomHandler();
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
          if (this.selectedProject._id !== '633d2757a4fbd61d20ee34ae') {
            this.textShow = true;
          } else {
            this.filterShow = true;
            this.textShow = false;
          }
          //console.log('done.');
        });
    },
    addMarker(coordinates, name, reading, timestamp, status, uid) {
      let graphicsToAdd = [];

      if (!Array.isArray(coordinates) || coordinates.length < 2 || typeof coordinates[0] !== 'number' || typeof coordinates[1] !== 'number') {
        return;
      }

      if (coordinates && coordinates[0] !== 0 && coordinates[0] !== null) {
        const url = this.markerPics[status] || this.markerPics.default;

        const marker = {
          type: 'picture-marker',
          url,
          width: '13px',
          height: '13px',
        };

        const point = {
          type: 'point',
          x: coordinates[0],
          y: coordinates[1],
        };

        var popupContent = `<p style="padding-top: 10px;">Last: ${reading}</p><p>${moment(timestamp).format('YYYY-MM-DD HH:mm')}</p>`;
        const key = `${coordinates[0]},${coordinates[1]}`;

        if (this.activeGraphics[key]) {
          popupContent = `<p>${name}</p><p>Last: ${reading}</p><p>${moment(timestamp).format('YYYY-MM-DD HH:mm a')}</p>`;
          if (this.activeGraphics[key] && this.activeGraphics[key].popupTemplate) {
            this.activeGraphics[key].popupTemplate.content += popupContent;
          }
        }
        if (this.shouldAddMarker(name)) {
          const graphic = new this.esriModules.Graphic({
            geometry: point,
            symbol: marker,
            popupTemplate: {
              title: name,
              content: popupContent,
              actions: [{
                id: 'show-instrument-id',
                title: 'chart',
                className: 'esri-icon-chart',
              }],
            },
            attributes: {
              instrument_id: uid,
              status,
            },
          });

          // console.log(graphic);
          const textSymbol = {
            type: 'text',
            text: name,
            color: 'black',
            haloColor: 'white',
            haloSize: '1px',
            xoffset: 0,
            yoffset: -15,
            font: {
              size: 10,
              weight: 'bold',
            },
          };

          let textGraphic = null;
          if (this.selectedProject._id !== '633d2757a4fbd61d20ee34ae') {
            textGraphic = new this.esriModules.Graphic({
              geometry: point,
              symbol: textSymbol,
            });
            textGraphic.visible = this.showTextGraphic;
          }

          if (!this.activeGraphics[name]) {
            this.activeGraphics[name] = {
              marker: graphic,
              text: textGraphic,
            };
          }

          this.activeGraphics[key] = graphic;
          if (!name.includes('System-'))
            graphicsToAdd.push(graphic);

          if (textGraphic !== null && this.selectedProject._id !== '633d2757a4fbd61d20ee34ae') {
            this.activeGraphics[name].text.visible = false;
            if (!name.includes('System-'))
              graphicsToAdd.push(textGraphic);
          }
        }
      }
      this.view.graphics.addMany(graphicsToAdd);
    },
    shouldAddMarker(name) {
      return !(name.includes('-NS')) && !(name.includes('-Y') && name.includes('STI'));
    },
    overrideBaseDynamicLayer() {
      this.CustomImageOverlayLayer = this.esriModules.BaseDynamicLayer.createSubclass({
        properties: {
          picUrl: null,
          extent: null,
          image: null,
          canvas: null,
          view: null,
          cSize: null,
        },
        getImageUrl(extent, width, height) {
          // 新Image對象，可以理解爲DOM
          if (!this.image) {
            this.image = new Image();
          }
          this.image.setAttribute('crossOrigin', 'anonymous');
          this.image.src = this.picUrl;
          // 創建canvas DOM元素，並設置其寬高和圖片一樣
          if (!this.canvas) {
            this.canvas = document.createElement('canvas');
          }
          this.canvas.width = this.cSize.width;
          this.canvas.height = this.cSize.height;

          // 左上角地理座標轉換屏幕座標,爲了獲取canvas繪製圖片的起點
          var mapPoint = {
            x: this.extent.xmin,
            y: this.extent.ymax,
            spatialReference: {
              wkid: 4326,
            },
          };
          var screenPoint = this.view.toScreen(mapPoint);

          // 根據extent範圍計算canvas繪製圖片的寬度以及高度
          var leftbottom = {
            x: this.extent.xmin,
            y: this.extent.ymin,
            spatialReference: {
              wkid: 4326,
            },
          };
          var screen_leftbottom = this.view.toScreen(leftbottom);

          var righttop = {
            x: this.extent.xmax,
            y: this.extent.ymax,
            spatialReference: {
              wkid: 4326,
            },
          };
          var screen_righttop = this.view.toScreen(righttop);

          if (this.picUrl.includes('O-A0058-005')) {
            var ctx = this.canvas.getContext('2d');
            ctx.globalAlpha = 0.2;
            ctx.drawImage(this.image, screenPoint.x, screenPoint.y, Math.abs(screen_righttop.x - screen_leftbottom.x), Math.abs(screen_righttop.y - screen_leftbottom.y));
          }

          this.canvas.getContext('2d').drawImage(this.image, screenPoint.x, screenPoint.y, Math.abs(screen_righttop.x - screen_leftbottom.x), Math.abs(screen_righttop.y - screen_leftbottom.y));
          return this.canvas.toDataURL('image/png');
        },
      });
    },
    updateCustomLayerUrl() {
      const customLayer = this.map.layers.items[6];
      customLayer.picUrl = `https://api-geoserver.geosupply.com.tw/rain/高鐵邊坡監測/HOUR_${this.selectedOption}.png`;
      //console.log(this.map.layers.items[6].picUrl);
      customLayer.refresh();
      setTimeout(() => {
        customLayer.refresh();
      }, 2000);
      this.view.zoom = 8;
      this.view.center = [120.606220, 24.05705];
    },
    updateCustomLayerUrl_SHP() {
      const layer = this.map.layers.items.find(lyr => lyr.title === '地質敏感');
      if (layer) {
        this.map.layers.remove(layer);
      }
      this.loading = true;
      const shp_layer = this.loadZipFromUrl(`https://api-geoserver.geosupply.com.tw/shp//L00${this.selectedOptionSHP}.zip`);
      //console.log(shp_layer);
      this.loading = false;
    },
    toggleShowSettings() {
      this.showSettings = !this.showSettings;
    },
    getExtentMarker(mapView) {
      if (this.selectedProject._id === '633d2757a4fbd61d20ee34ae') {
        let visibleGraphics = [];
        const currentExtent = this.view.extent;
        mapView.graphics.forEach(graphic => {
          if (currentExtent.contains(graphic.geometry) && graphic.visible) {
            visibleGraphics.push(graphic);
          }
        });

        // console.log(visibleGraphics);

        let counts = {
          STI: { num: 0, status: 'GREEN' },
          MC: { num: 0, status: 'GREEN' },
          DS: { num: 0, status: 'GREEN' },
          SAA: { num: 0, status: 'GREEN' },
          TM: { num: 0, status: 'GREEN' },
          LC: { num: 0, status: 'GREEN' },
          RAIN: { num: 0, status: 'GREEN' },
          ELP: { num: 0, status: 'GREEN' },
          ETI: { num: 0, status: 'GREEN' },
        };

        visibleGraphics.forEach(inst => {
          Object.keys(counts).forEach(key => {
            if (inst.popupTemplate.title.includes(key)) {
              counts[key].num++;
              if (inst.attributes.status === 'AL' && counts[key].status !== 'WSL') {
                counts[key].status = 'AL';
              }
              if (inst.attributes.status === 'WSL') {
                counts[key].status = 'WSL';
              }
            }
          });
        });

        Object.keys(counts).forEach(key => {
          if (counts[key].num > 0)
            console.log(`目前可視範圍內 ${key}: ${counts[key].num}, 狀態: ${counts[key].status}`);
        });

        this.counts = counts;
        const instrumentCounts = document.querySelector('.instrument-counts');
        if (instrumentCounts) {
          this.updateInstrumentCountsContent(instrumentCounts);
        }
      }
    },
    toggleInstrumentCounts() {
      const countsElement = document.querySelector('.instrument-counts');
      if (countsElement.style.display === 'none' || !countsElement.style.display) {
        countsElement.style.display = 'block';
      } else {
        countsElement.style.display = 'none';
      }
    },
    initializeZoomHandler() {
      this.view.watch('zoom', (newZoom) => {
        this.now_zoom = newZoom;
        this.getExtentMarker(this.view);
        for (let key in this.activeGraphics) {
          let graphic = this.activeGraphics[key];
          if (graphic.text && this.showTextGraphic && graphic.marker.visible) { // If textGraphic exists
            graphic.text.visible = newZoom > 12; // Set its visibility based on the zoom level
          }
        }
      });
      this.view.watch('stationary', (isStationary) => {
        if (isStationary) {
          this.getExtentMarker(this.view);
        }
      });
    },
    handleCheckboxChange(checked, typeName) {
      // Iterate over each graphic in the view
      this.view.graphics.forEach(graphic => {
        // Set visibility to false by default
        graphic.visible = false;
        this.toggleTextGraphic();
        if (graphic.popupTemplate)
          for (const selectedInstrument of this.selectedInstruments) {
            if (graphic.popupTemplate.title.includes(selectedInstrument) && !graphic.popupTemplate.title.includes('-NS')
              && !(graphic.popupTemplate.title.includes('-Y') && graphic.popupTemplate.title.includes('STI'))) {
              graphic.visible = true;
              break;
            }
          }
      });
    },
    changeLayer() {
      this.map.layers.items.forEach(lyr => {
        lyr.visible = false;
      });
      for (var layer in this.layerVisible) {
        this.layerVisible[layer].visible = false;
      }

      if (this.selectedLayer != 0) {
        this.map.layers.items[this.selectedLayer - 1].visible = true;
      }
    },
    toggleTextGraphic() {
      for (let key in this.activeGraphics) {
        let graphicObj = this.activeGraphics[key];
        if (graphicObj.text) {
          if (this.now_zoom > 12 && graphicObj.marker.visible)
            graphicObj.text.visible = this.showTextGraphic;
        }
      }
    },
    showMarker(instrument) {
      const key = `${instrument.coordinates[0]},${instrument.coordinates[1]}`;
      const graphic = this.activeGraphics[key];

      if (graphic) {
        this.view.goTo({
          target: graphic.geometry,
          zoom: 18,
        }).then(() => {
          if (instrument.status !== 'down')
            this.view.popup.open({
              location: graphic.geometry,
              features: [graphic],
            });
        });
      }
    },
    getStatusColor(status) {
      switch (status) {
        case 'WSL': return '#F56C6C';
        case 'AL': return '#FFAD00';
        case 'WL': return '#4188FF';
      }
    },
    selectLayers(title, visible) {
      if (this.layerVisible.rain.visible)
        this.updateCustomLayerUrl();
      this.map.layers.items.forEach(lyr => {
        if (lyr.title.includes(title))
          lyr.visible = visible;
      });
    },
    getShakemapImageURI() {
      const url = 'https://opendata.cwa.gov.tw/api/v1/rest/datastore/E-A0015-001';
      const params = {
        Authorization: 'CWB-F9D03D48-7B12-4428-9F33-CDF5E9D1B162',
        format: 'JSON',
      };
      axios.get(url, { params })
        .then(response => {
          this.shakemapImageURI = response.data.records.Earthquake[0].ShakemapImageURI;
        })
        .catch(error => {
          console.error(error);
        });
    },
    reloadSetting(id) {
      switch (id) {
        case 'PHOTO_MIX':
          return i18n.t('map.orthoimagery_mix');
        case 'EMAP':
          return i18n.t('map.general_electronic_map');
        case 'PHOTO2':
          return i18n.t('map.orthoimagery_general');
        case 'None':
          return 'Default';
      }
    },
  },
};
</script>